import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bordered-images media-posts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumbs = _resolveComponent("breadcrumbs")!
  const _component_loader = _resolveComponent("loader")!
  const _component_media_post_content = _resolveComponent("media-post-content")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_breadcrumbs, { elements: _ctx.breadcrumbs }, null, 8, ["elements"]),
    _createVNode("h1", null, "Media post — " + _toDisplayString(_ctx.post.created), 1),
    _createVNode(_component_loader, { loaded: _ctx.loaded }, null, 8, ["loaded"]),
    (_ctx.loaded === true)
      ? (_openBlock(), _createBlock("ul", _hoisted_1, [
          _createVNode("li", null, [
            _createVNode(_component_media_post_content, { post: _ctx.post }, null, 8, ["post"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}