
    import { defineComponent } from 'vue';
    import MediaPostContent from '@/components/MediaPostContent.vue';
    import Loader from '@/components/Loader.vue';
    import Breadcrumbs from '@/components/Breadcrumbs.vue';

    export default defineComponent({
        name: 'MediaSinglePost',
        components: {
            MediaPostContent,
            Loader,
            Breadcrumbs,
        },

        data() {
            return {
                post: {
                    id: 0,
                    message: '',
                    created: '',
                    toot_id: '',
                    items: [],
                    tags: [],
                },
                breadcrumbs: [
                    {
                        name: 'Media',
                        url: 'media',
                    },
                ],
                loaded: false,
            }
        },

        async created() {
            await this.fetchPost();
        },

        methods: {
            async fetchPost() {
                this.loaded = false;

                const response = await fetch(`https://virtualwolf.org/rest/media/posts/${this.$route.params.id}`);
                const data = await response.json();

                this.post = data;
                this.loaded = true;

                this.breadcrumbs.push({
                    name: data.created,
                    url: `posts/${this.post.id}`,
                })

                document.title = `Media post - ${data.created} | virtualwolf.xyz`
            }
        }
    })
